export default {
  'Vai alla sezione': 'Vai alla sezione',
  'Clicca qui per scaricare la tabella in excel': 'Clicca qui per scaricare la gamma di produzione in excel',
  'GAMME DI PRODUZIONE': 'GAMME DI PRODUZIONE',
  'Laminati e profili speciali in barre e rotoli': 'Laminati e profili speciali in barre e rotoli',
  'Dal 1961 la nostra impronta nella siderurgia': 'Dal 1961 la nostra impronta nella siderurgia',
  'Scopri di più': 'Scopri di più',
  'Contattaci per avere maggiori informazioni': 'Contattaci per avere maggiori informazioni',
  'Qualità e Laminati': 'Qualità e Laminati',
  'L’intera gamma di produzione viene offerta secondo le norme Europee EN aggiornate Unsider': 'L’intera gamma di produzione viene offerta secondo le norme Europee EN aggiornate Unsider',
  'Qualità degli acciai': 'Qualità degli acciai',
  'Apparecchiature per controllo del laminato': 'Apparecchiature per controllo del laminato',
  'Laboratorio ': 'Laboratorio',
  'Associati ': 'Associati',
  'Chi Siamo': 'Chi Siamo',
  'Certificazioni ': 'Certificazioni',
  'FAM S.p.A. è certificata ISO 9001 dal 1997.': 'FAM S.p.A. è certificata ISO 9001 dal 1997.',
  'Scopri le nostre Produzioni': 'Scopri le nostre Produzioni',
  'Scopri tutti i nostri servizi': 'Scopri tutti i nostri servizi',
  'PROFILI SPECIALI': 'PROFILI SPECIALI',
  'Clicca sulle icone sottostanti per scaricare gli allegati.': 'Clicca sulle icone sottostanti per scaricare gli allegati.',
  'Info e contatti': 'Info e contatti',
  'Centralino ': 'Centralino',
  'Ufficio Commerciale e Qualità': 'Ufficio Commerciale e Qualità',
  'Ufficio Acquisti': 'Ufficio Acquisti',
  'Ufficio Spedizioni': 'Ufficio Spedizioni',
  'Ufficio Amministrativo': 'Ufficio Amministrativo',
  'L\'azienda': 'L\'azienda',
  'Prodotti e Servizi': 'Prodotti e Servizi',
  'Gamma di Produzione': 'Gamma di Produzione',
  'La politica della qualità': 'La politica della qualità',

  'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.': 'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.',
  'Accetta ': 'Accetta',
  'Rifiuta ': 'Rifiuta',
  'Impostazioni cookie': 'Impostazioni cookie',
  'Impostazioni ': 'Impostazioni',
  'Cookie strettamente necessari': 'Cookie strettamente necessari',
  'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.': 'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.',
  'Cookie di terze parti': 'Cookie di terze parti',
  'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.': 'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.',
  'Salva le modifiche': 'Salva le modifiche',
  'Attivo ': 'Attivo'
}
