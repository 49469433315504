// translation.js
import it from './langs/it'
import en from './langs/en'
import es from './langs/es'

const translations = {
  it,
  en,
  es
}

export function translate(key, lang) {
  const translation = translations[lang]
  if (!translation) {
    console.warn(`Translation for language ${lang} not found.`)
    return key
  }
  return translation[key] || key
}
