export default {
  'Vai alla sezione': 'Read More',
  'Clicca qui per scaricare la tabella in excel': 'Click here to download production range in excel',
  'GAMME DI PRODUZIONE': 'PRODUCTION RANGE',
  'Laminati e profili speciali in barre e rotoli': 'Hot rolled and special profiles in bars and rolls',
  'Laminati e Profili Speciali in barre e rotoli': 'Hot Rolled and Special Profiles in bars and rolls',
  'Dal 1961 la nostra impronta nella siderurgia': 'From 1961 our footprint in steel industry',
  'Scopri di più': 'Discover More',
  'Contattaci per avere maggiori informazioni': 'Contact us for further information',
  'Qualità e Laminati': 'Quality and Hot Rolled Profiles',
  'L’intera gamma di produzione viene offerta secondo le norme Europee EN aggiornate Unsider': 'The entire production range is available with the following steel typologies, according to European En standards Unsider updated:',
  'Qualità degli acciai': 'Steel Quality',
  'Apparecchiature per controllo del laminato': 'Testing',
  'Laboratorio ': 'Laboratory',
  'Associati ': 'Affiliated',
  'Chi Siamo': 'About Us',
  'Certificazioni ': 'Certificazioni',
  'FAM S.p.A. è certificata ISO 9001 dal 1997.': 'FAM S.p.A. is certified ISO 9001 from 1997.',
  'Scopri le nostre Produzioni': 'Discover our Productions',
  'Scopri tutti i nostri servizi': 'Discover our Services',
  'PROFILI SPECIALI': 'SPECIAL PROFILES',
  'Clicca sulle icone sottostanti per scaricare gli allegati.': 'Click on the icons below to download the attachments.',
  'Info e contatti': 'Info and contacts',
  'Centralino ': 'Contact Center',
  'Ufficio Commerciale e Qualità': 'Commercial Office',
  'Ufficio Acquisti': 'Purchasing Office',
  'Ufficio Spedizioni': 'Shipping Office',
  'Ufficio Amministrativo': 'Administrative Office',
  'L\'azienda': 'Company',
  'Prodotti e Servizi': 'Products & Services',
  'Gamma di Produzione': 'Production Range',
  'La politica della qualità': 'Quality Policy',

  'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.': 'This site does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience. By clicking on the "Reject" button, you will close the banner without giving consent to any cookies, except those necessary for the correct functioning of the site.',
  'Accetta ': 'Accept',
  'Rifiuta ': 'Decline',
  'Impostazioni cookie': 'Cookie Settings',
  'Impostazioni ': 'Settings',
  'Cookie strettamente necessari': 'Strictly necessary cookies',
  'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.': 'Strictly necessary cookies are technical cookies, which do not collect data, necessary for the correct display of the website.',
  'Cookie di terze parti': 'Third party cookies',
  'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.': 'Google Maps is a map viewing service managed by Google that helps visitors locate your company. This service is provided using exclusively technical cookies.',
  'Salva le modifiche': 'Save settings',
  'Attivo ': 'Active'
}
