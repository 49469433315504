export default {
  'Vai alla sezione': 'Leer más',
  'Clicca qui per scaricare la tabella in excel': 'Pulsa aquí para descargar la gama de producción en excel',
  'GAMME DI PRODUZIONE': 'GAMA DE PRODUCCIÓN',
  'Laminati e profili speciali in barre e rotoli': 'Laminados y perfiles especiales en barras y rollos',
  'Laminati e Profili Speciali in barre e rotoli': 'Laminados y Perfiles Especiales en barras y rollos',
  'Dal 1961 la nostra impronta nella siderurgia': 'Desde 1961 nuestra huella en la siderurgía',
  'Scopri di più': 'Descubre más',
  'Contattaci per avere maggiori informazioni': 'Contáctanos para obtener más información',
  'Qualità e Laminati': 'Calidad y Perfiles laminados en caliente',
  'L’intera gamma di produzione viene offerta secondo le norme Europee EN aggiornate Unsider': 'Toda la gama de producción se ofrece en las siguientes calidades de acero de acuerdo con las normas Europeas actualizadas Unsider:',
  'Qualità degli acciai': 'Calidad del los acero',
  'Apparecchiature per controllo del laminato': 'Pruebas',
  'Laboratorio ': 'Laboratorio',
  'Associati ': 'Asociados',
  'Chi Siamo': 'Quiénes Somos',
  'Certificazioni ': 'Certificaciones',
  'FAM S.p.A. è certificata ISO 9001 dal 1997.': 'FAM S.p.A. está certificada ISO 9001 desde 1997.',
  'Scopri le nostre Produzioni': 'Descubre nuestras Producciones',
  'Scopri tutti i nostri servizi': 'Descubre todos nuestros servicios',
  'PROFILI SPECIALI': 'PERFILES ESPECIALES',
  'Clicca sulle icone sottostanti per scaricare gli allegati.': 'Pulsa los siguientes iconos para descargar los anexos adjuntos',
  'Info e contatti': 'Información y contactos',
  'Centralino ': 'Centralita',
  'Ufficio Commerciale e Qualità': 'Oficina Comercial y de Calidad',
  'Ufficio Acquisti': 'Oficina de Compras',
  'Ufficio Spedizioni': 'Oficina de Envíos',
  'Ufficio Amministrativo': 'Oficina Administrativa',
  'L\'azienda': 'Empresa',
  'Prodotti e Servizi': 'Productos y Servicios',
  'Gamma di Produzione': 'Gama de Producción',
  'La politica della qualità': 'Política por la calidad',

  'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.': 'Este sitio no utiliza cookies de creación de perfiles. En su lugar, se utilizan cookies técnicas, incluidas cookies de terceros, para mejorar la experiencia de navegación. Al hacer clic en el botón "Rechazar", cerrará el banner sin dar su consentimiento a ninguna cookie, excepto las necesarias para el correcto funcionamiento del sitio.',
  'Accetta ': 'Aceptar',
  'Rifiuta ': 'Rechazar',
  'Impostazioni cookie': 'Configuración',
  'Impostazioni ': 'Configuración',
  'Cookie strettamente necessari': 'Cookies estrictamente necesarias',
  'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.': 'Las cookies estrictamente necesarias son cookies técnicas, que no recopilan datos necesarios para la correcta visualización del sitio web.',
  'Cookie di terze parti': 'Cookies de terceros',
  'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.': 'Google Maps es un servicio de visualización de mapas gestionado por Google que ayuda a los visitantes a localizar su empresa. Este servicio se presta utilizando cookies exclusivamente técnicas.',
  'Salva le modifiche': 'Guardar ajustes',
  'Attivo ': 'Activo'
}
