import { gsap } from 'gsap'
// import SplitText from '../SplitText'

gsap.config({ force3D: true })

const animations = new Map()

//
animations.set('fade-in-initial', (el) => {
    gsap.set(el, {
        autoAlpha: 0,
        y: 40
    })
})

animations.set('ciao-initial', (el) => {
  gsap.set(el, {
    scale: 1
  })
})

animations.set('fade-in', (el, opts) => {
    const anim = gsap.to(el, {
        autoAlpha: 1,
        y: 0,
        duration: opts.duration || 2,
        delay: opts.delay || 2
    })

    return anim
})

animations.set('ciao', (el, opts) => {
  const anim = gsap.to(el, {
    scale: 1.2,
    duration: opts.duration || 2,
    delay: opts.delay || 2
  })

  return anim
})

// ------------------------------------------------------------------------------------------------------

export default animations
